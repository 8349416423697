import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/SEO"
import Banner from "../components/Banner"
import Layout from "../components/layout/Layout"
import Form from "../components/Form"
import { SectionWrapper, SectionInnerWrapper, SectionHeader, SectionSubHeader } from "../components/Wrapper"

const EnquiryPage = ({ data }) => {
    return (
        <Layout>
            <SEO
                title={`All Ins Agency - Enquiry`}
                description={`All Ins Agency`}
            />
            <Banner heading={data.allStrapiBanner.edges[0].node.heading} subHeading={data.allStrapiBanner.edges[0].node.subheading} trail={data.allStrapiBanner.edges[0].node.name} trailLink="/enquiry" image={data.site.siteMetadata.apiUrl + data.allStrapiBanner.edges[0].node.fields.imageUrl} />
            <SectionWrapper>
                <SectionInnerWrapper>
                    <SectionSubHeader>QUESTIONS?</SectionSubHeader>
                    <SectionHeader>GET IN TOUCH</SectionHeader>
                    <div className="mt-10 mx-0 md:mx-4">
                        <Form 
                            inputs={{
                                name: true, 
                                email: true,
                                phone: true,
                                message: true
                            }}
                        />
                    </div>
                </SectionInnerWrapper>
            </SectionWrapper>
        </Layout >
    )
}

export default EnquiryPage

export const enquiryQuery = graphql`
    query EnquiryQuery {
        allStrapiBanner(filter: {page: {eq: "/enquiry"}}, sort: {fields: fields___sortindexnum, order: ASC}) {
            edges {
                node {
                    subheading
                    heading
                    name
                    page
                    fields {
                        imageUrl
                        sortindexnum
                    }
                }
            }
        }
        site {
            siteMetadata { 
                apiUrl
            }
        }
    }
`